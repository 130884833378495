import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { APIController } from '../controller/APIController';
import { gsap, Power1 } from "gsap";

export class SceneController
{
	private getEpisodeBindThis: Function;
	private postEpisodeYourhistoryEpisodeBindThis: Function;
	private setTopBindThis: Function;

	constructor()
	{
		this.getEpisodeBindThis = this.getEpisode.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.LIFF_INIT_COMPLETE, this.getEpisodeBindThis);

		this.postEpisodeYourhistoryEpisodeBindThis = this.postEpisodeYourhistoryEpisode.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.GET_EPISODE_COMPLETE, this.postEpisodeYourhistoryEpisodeBindThis);

		this.setTopBindThis = this.setTop.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_COMPLETE, this.setTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.POST_EPISODE_YOURHISTORY_EPISODE_ERROR, this.setTopBindThis);

	}
	public getEpisode(): void
	{
		APIController.getEpisode();
		
	}
	public postEpisodeYourhistoryEpisode(): void
	{
		APIController.postEpisodeYourhistoryEpisode();
		
	}
	public setTop(): void
	{
		gsap.to(document.querySelector("#wrapper"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, onComplete:this.setTopComplete.bind(this)});

	}
	public setTopComplete(): void
	{
		if(DataStorage.PAGE=="top")
		{
			if(DataStorage.QUICK_LINK=="top")
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));
			}
			else if(DataStorage.QUICK_LINK=="sendYourhistory")
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOURHISTORY));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));
			}
			else
			{
				if(DataStorage.isDebug)
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));
				}
				else
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_OPENING));
				}
			}
		}
		else if(DataStorage.PAGE=="message")
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_MESSAGE_INPUT));
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));
		}
	}
}
