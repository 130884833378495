import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { APIController } from '../controller/APIController';
import liff from '@line/liff';

export class LIFFTController
{
	// private text: HTMLElement;

	private initCompleteBindThis: Function;

	constructor()
	{
		// this.text = document.querySelector("._log")!;
		// this.text.innerText += DataStorage.LIFF_ID;

		if(!DataStorage.isDebug)
		{
			liff.init({
				liffId: DataStorage.LIFF_ID,
			})
			.then(() => {
				console.log("start to use LIFF's api");
				// this.text.innerText += "\n start to use LIFF's api";
	
				this.init();
	
				// liff.init()実行前の1次リダイレクト先URLにはユーザーのアクセストークンなどの機密情報が含まれるため、
				// Google Analyticsなど外部のロギングツールにURLの情報を送らないように注意してください。
				// ga('send', 'pageview');
			})
			.catch((err) => {
				console.log(err);
			});
		}
		else
		{
			this.initComplete();
		}
	}
	public init(): void
	{
		// print the environment in which the LIFF app is running
		console.log("---------------------------------");
		console.log("Language:", liff.getLanguage());
		console.log("Version:", liff.getVersion());
		console.log("InClient:", liff.isInClient());
		console.log("LoggedIn:", liff.isLoggedIn());
		console.log("OS:", liff.getOS());
		console.log("LineVersion:", liff.getLineVersion());
		console.log("---------------------------------");

		//クエリ取得
		if(new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("page"))
		{
			DataStorage.QUICK_LINK = new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("page");
		}
		if(new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("qr"))
		{
			DataStorage.qr_type = Number(new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("qr").toString().split("_")[0]);
		}
		if(new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("quickBookOpen"))
		{
			DataStorage.quickBookOpen = new URLSearchParams(decodeURIComponent(window.location.search).replace("?liff.state=", "")).get("quickBookOpen");
		}

		// this.text.innerText += "\n------------------------";
		// this.text.innerText += "\n getLanguage:"+liff.getLanguage();
		// this.text.innerText += "\n getVersion:"+liff.getVersion();
		// this.text.innerText += "\n isInClient:"+liff.isInClient();
		// this.text.innerText += "\n isLoggedIn:"+liff.isLoggedIn();
		// this.text.innerText += "\n getOS:"+liff.getOS();
		// this.text.innerText += "\n getLineVersion:"+liff.getLineVersion();
		// this.text.innerText += "\n------------------------";
		
		// get access token
		if (!liff.isLoggedIn() && !liff.isInClient()) {
			// window.alert('To get an access token, you need to be logged in. Tap the "login" button below and try again.');
			// this.text.innerText += "\n To get an access token, you need to be logged in. Tap the login button below and try again.";
			location.href = "https://line.me/R/ti/p/@644atkym?oat_content=qr#~";
		} else {
			const accessToken = liff.getAccessToken();
			console.log("accessToken:", accessToken);
			// this.text.innerText += "\n accessToken:"+accessToken;
			DataStorage.accessToken = accessToken;
		}

		// APIController.getEpisode();
		// APIController.getEpisodeByEpisodeId(1);
		// APIController.getEpisodeByEpisodeId(4);
		// APIController.getEpisodeByEpisodeId(10);
		// APIController.getEpisodeByEpisodeId(11);
		// APIController.getEpisodeByEpisodeId(12);
		// APIController.postEpisodeYourhistoryEpisode();

		// document.querySelector('#button').addEventListener('click', function()
		// {
		// 	const inputElement = document.querySelector('#text') as HTMLInputElement;
		// 	APIController.postEpisodeSend(inputElement.value);
		// }, false);

		//初回だけPOST QRType Sendを叩く
		if(DataStorage.PAGE=="top" && !DataStorage.QUICK_LINK)
		{
			this.initCompleteBindThis = this.initComplete.bind(this);
			SceneEvent.getInstance().addEventListener(SceneEvent.POST_QRTYPE_SEND_COMPLETE, this.initCompleteBindThis);

			APIController.postQRTypeSend();
		}
		else
		{
			this.initComplete();
		}
	}
	public initComplete(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.LIFF_INIT_COMPLETE));

	}
}