import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap, Power1 } from "gsap";

export class ErrorModal
{
	private element: HTMLElement;

	private addBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#errorModal");

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.SERVER_ERROR, this.addBindThis);

	}
	public add(): void
	{
		gsap.killTweensOf(this.element);
		gsap.to(this.element, 0.3, {css:{opacity:1, display:"block"}, ease:Power1.easeInOut});

	}
}